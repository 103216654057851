<template>
  <div v-if="form">
    <b-container class="container-box">
      <b-row class="no-gutters">
        <b-col>
          <h1 class="font-weight-bold header-main text-uppercase mb-3">
            รายละเอียดรูปหมวดหมู่ {{ form.category.name }}
          </h1>
        </b-col>
      </b-row>

      <div class="bg-white p-3" v-if="$isLoading">
        <b-row>
          <b-col md="6">
            <UploadFile
              textFloat="รูปหมวดหมู่"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="all"
              name="thumbnail"
              :fileName="form.category.imageUrl"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 1:1 ขนาดไม่เกิน 10 MB"
              isRequired
              v-on:onFileChange="onImageChange"
              v-on:delete="deleteImage"
              :v="$v.form.category.imageUrl"
            />
            <div
              class="preview-box img-category"
              v-bind:style="{
                'background-image': 'url(' + form.category.imageUrl + ')',
              }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingImage"
              />
            </div> </b-col
        ></b-row>

        <!-- <b-row class="mt-3">
          <b-col md="6">
            <UploadFile
              textFloat="ภาพแบนเนอร์สำหรับคอมพิวเตอร์"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="all"
              name="thumbnail"
              :fileName="form.category.bannerImageUrl"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 16:9 ขนาดไม่เกิน 50 MB"
              isRequired
              v-on:onFileChange="onBannerImageChange"
              v-on:delete="deleteBannerImage"
              :v="$v.form.category.bannerImageUrl"
            />
            <div
              class="preview-box banner"
              v-bind:style="{
                'background-image': 'url(' + form.category.bannerImageUrl + ')',
              }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingBannerImage"
              />
            </div>
          </b-col>
          <b-col md="6">
            <UploadFile
              textFloat="ภาพแบนเนอร์สำหรับมือถือ"
              placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
              format="all"
              name="thumbnail"
              :fileName="form.category.mobileImageUrl"
              text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 16:9 ขนาดไม่เกิน 50 MB"
              isRequired
              v-on:onFileChange="onMobileImageChange"
              v-on:delete="deleteMobileImage"
              :v="$v.form.category.mobileImageUrl"
            />
            <div
              class="preview-box banner"
              v-bind:style="{
                'background-image': 'url(' + form.category.mobileImageUrl + ')',
              }"
            >
              <img
                src="/img/loading.svg"
                class="loading"
                alt="loading"
                v-if="isLoadingMobileImage"
              />
            </div>
          </b-col>
        </b-row> -->
      </div>

      <div class="bg-white p-3 mt-3" v-if="$isLoading">
        <b-button
          v-b-toggle.collapse-cat2
          class="py-2 pl-0 pr-1 bg-white collapse-cat2"
        >
          <h1 class="font-weight-bold header-main text-uppercase mb-3 d-inline">
            ภาพหมวดหมู่ลำดับชั้นที่ 2
          </h1>
          <font-awesome-icon
            icon="chevron-right"
            class="icon float-right mt-2"
          />
          <font-awesome-icon
            icon="chevron-down"
            class="icon float-right mt-2"
          />
        </b-button>
        <b-collapse id="collapse-cat2" class="mt-2 collapse-details" visible>
          <b-card class="card-box card-seo lol">
            <div v-if="items.length > 0">
              <div class="py-2 px-3 bg-gray">ชื่อหมวดหมู่</div>
              <div v-for="(item, index) in items" :key="index">
                <b-button
                  v-b-toggle="'collapse-cat2-' + index"
                  class="collapse-cat2-btn py-2 px-3"
                >
                  {{ item.name }}
                  <font-awesome-icon
                    icon="chevron-right"
                    class="icon float-right mt-1"
                  />
                  <font-awesome-icon
                    icon="chevron-down"
                    class="icon float-right mt-1"
                  />
                </b-button>
                <b-collapse
                  :id="'collapse-cat2-' + index"
                  class="mt-2 collapse-details"
                >
                  <b-card class="card-box card-seo lol">
                    <b-row>
                      <b-col cols="6">
                        <div
                          class="image b-contain"
                          v-bind:style="{
                            'background-image': 'url(' + item.imageUrl + ')',
                          }"
                        ></div
                      ></b-col>
                      <b-col cols="3" class="m-auto text-center">
                        <p class="m-0">ภาพหมวดหมู่</p></b-col
                      >
                      <b-col cols="3" class="m-auto text-center">
                        <b-button
                          variant="link"
                          class="px-1 py-0 text-dark"
                          @click="editSubCatImg(item.imageUrl, item.id)"
                        >
                          แก้ไข
                        </b-button></b-col
                      >
                    </b-row>
                  </b-card>
                </b-collapse>
              </div>
            </div>
            <div v-else class="p-5 text-center">ไม่พบข้อมูล</div>
          </b-card>
        </b-collapse>
        <b-row class="mt-5">
          <b-col md="6">
            <router-link to="/category/imagelist">
              <b-button :disabled="isDisable" class="btn-details-set btn-cancel"
                >ย้อนกลับ</b-button
              >
            </router-link>
          </b-col>
          <b-col md="6" class="text-sm-right">
            <button
              type="button"
              @click="checkForm(0)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึก
            </button>
            <button
              type="button"
              @click="checkForm(1)"
              :disabled="isDisable"
              class="btn btn-main btn-details-set ml-md-2 text-uppercase"
            >
              บันทึกและออก
            </button>
          </b-col>
        </b-row>
      </div>
    </b-container>

    <b-modal
      id="changeImgModal"
      ref="changeImgModal"
      hide-header
      hide-footer
      centered
      body-class="p-4"
    >
      <div class="modal-header border-0 px-0 pt-0">
        <button
          type="button"
          aria-label="Close"
          class="close"
          @click="$bvModal.hide('changeImgModal')"
        >
          ×
        </button>
      </div>
      <div>
        <b-container class="p-0">
          <b-row>
            <b-col>
              <UploadFile
                textFloat="อัพโหลดภาพ"
                placeholder="กรุณาเลือกไฟล์เพื่ออัพโหลด"
                format="image"
                name="thumbnail"
                :fileName="subCatImg"
                text="*กรุณาอัพโหลดไฟล์สกุล .png, .jpg ขนาด 1140 x 450 px ขนาดไม่เกิน 50 MB"
                isRequired
                v-on:onFileChange="onImageSubCatChange"
                v-on:delete="deleteSubCatImage"
              />
              <div
                class="preview-box image b-contain"
                v-bind:style="{
                  'background-image': 'url(' + subCatImg + ')',
                }"
              >
                <img
                  src="/img/loading.svg"
                  class="loading"
                  alt="loading"
                  v-if="isLoadingSubCatImage"
                />
              </div>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col md="6">
              <b-button
                @click="$bvModal.hide('changeImgModal')"
                class="btn-details-set btn-cancel"
                :disabled="isDisableModal"
                >ปิด</b-button
              >
            </b-col>
            <b-col md="6" class="text-sm-right">
              <button
                type="button"
                class="btn btn-main btn-details-set ml-md-2 text-uppercase"
                :disabled="isDisableModal"
                @click="uploadCatImg"
              >
                บันทึก
              </button>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>

    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
    <ModalLoading ref="modalLoading" :hasClose="false" />
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import UploadFile from "@/components/inputs/UploadFile";
import { required } from "vuelidate/lib/validators";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import ModalLoading from "@/components/modal/alert/ModalLoading";
import Vue from "vue";

export default {
  name: "CategoryImgDetails",
  components: {
    InputText,
    UploadFile,
    ModalAlert,
    ModalAlertError,
    ModalLoading,
  },
  data() {
    return {
      id: this.$route.params.id,
      isLoadingImage: false,
      isLoadingBannerImage: false,
      isLoadingMobileImage: false,
      isLoadingSubCatImage: false,
      coverImgType: 1,
      showVideo: "",
      showPreview: "",
      mobileCoverImgType: 1,
      mobileShowVideo: "",
      mobileShowPreview: "",
      isEdit: false,
      images: "",
      mobileImages: "",
      modalMessage: "",
      isDisable: false,
      isDisableModal:true,
      fileNameComputer: "",
      fileNameMobile: "",
      languageList: [],
      languageActive: 1,
      imageLogoLang: "",
      form: null,
      subCatImg: "",
      subCatId: 0,
      items: [],
      isBusy: false,
      rows: 0,
      fields: [
        {
          key: "imageUrl",
          label: "วันที่ทำรายการ",
          class: "w-100px",
        },
        {
          key: "name",
          label: "ชื่อ Tier",
          class: "w-100px",
        },
        {
          key: "id",
          label: "คะแนนเดิม",
          class: "w-100px",
        },
      ],
      imgTypeId: 22
    };
  },
  validations: {
    form: {
      category: {
        imageUrl: { required },
        // bannerImageUrl: { required },
        // mobileImageUrl: { required },
      },
    },
  },
  created: async function () {
    await this.getDatas();
    await this.getSubCategoryImgs();
    this.$isLoading = true;
  },
  methods: {
    getDatas: async function () {
      this.$isLoading = false;

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/category/ParentCategory/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.form = data.detail;

        this.$isLoading = true;
      }
    },
    getSubCategoryImgs: async function () {
      this.$isBusy = false;

      let data = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/category/ChildCategory/${this.id}`,
        null,
        this.$headers,
        null
      );

      if (data.result == 1) {
        this.items = data.detail;
        //this.rows = data.detail.count;
        this.isBusy = false;
      }
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;

        this.form.category.imageUrl = this.images;
      };
    },
    onImageSubCatChange(img) {
      this.isLoadingSubCatImage = true;
      this.isDisableModal = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingSubCatImage = false;
        this.isDisableModal = false;

        this.subCatImg = this.images;
      };
    },
    onBannerImageChange(img) {
      this.isLoadingBannerImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingBannerImage = false;
        this.isDisable = false;

        this.form.category.bannerImageUrl = this.images;
      };
    },
    onMobileImageChange(img) {
      this.isLoadingMobileImage = true;
      this.isDisable = true;

      var reader = new FileReader();
      reader.readAsDataURL(img);

      reader.onload = async () => {
        this.images = await this.saveImagetoDb(reader.result);
        this.isLoadingMobileImage = false;
        this.isDisable = false;

        this.form.category.mobileImageUrl = this.images;
      };
    },
    saveImagetoDb: async function (img) {
      var imgData = {
        base64: img,
        type: this.imgTypeId
      };

      let data = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/image/save`,
        null,
        this.$headers,
        imgData
      );

      if (data.result == 1) {
        return data.detail.url;
      }
    },
    deleteImage() {
      this.form.category.imageUrl = null;
    },
    deleteBannerImage() {
      this.form.category.bannerImageUrl = null;
    },
    deleteMobileImage() {
      this.form.category.mobileImageUrl = null;
    },
    deleteSubCatImage() {
      this.subCatImg = null;
      this.isDisableModal = false;
    },
    checkForm: async function (flag) {
      this.$v.form.$touch();

      if (this.$v.$error) {
        return;
      }
      this.flag = flag;
      this.submit();
    },
    submit: async function () {
      this.isDisable = true;
      this.$refs.modalLoading.show();

      let data = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/category/ParentCategory`,
        null,
        this.$headers,
        this.form
      );

      this.modalMessage = data.message;
      this.isDisable = false;
      this.$refs.modalLoading.hide();
      if (data.result == 1) {
        this.existId = data.detail;
        this.modalMessage = "สำเร็จ";
        this.$refs.modalAlert.show();

        if (this.flag == 1) {
          setTimeout(() => {
            this.$router.push({
              path: `/category/imagelist`,
            });
          }, 3000);
        } else {
          setTimeout(() => {
            this.$refs.modalAlert.hide();
          }, 3000);
          if (this.id > 0) {
            this.getDatas();
          }
        }
      } else {
        this.$refs.modalAlertError.show();
      }
    },
    editSubCatImg(img, id) {
      this.subCatId = id;
      this.subCatImg = img;
      this.isDisableModal = true;
      this.$refs.changeImgModal.show();
    },
    uploadCatImg: async function () {
      this.isDisableModal = true;
      this.$refs.modalLoading.show();

      let request = {
        category: {
          id: this.subCatId,
          imageUrl: this.subCatImg,
        },
      };

      let resData = await this.$callApi(
        "put",
        `${this.$baseUrl}/api/category/ParentCategory`,
        null,
        this.$headers,
        request
      );
      this.isDisableModal = false;
      this.$refs.modalLoading.hide();
      if (resData.result == 1) {
        this.$refs.changeImgModal.hide();
        this.subCatImg = "";
        this.subCatId = 0;
        this.getSubCategoryImgs();
      }
    },
  },
};
</script>

<style scoped>
.panel {
  font-size: 17px;
  color: #16274a;
}

.banner {
  padding-bottom: 42.9%;
}

.img-category {
  width: 50%;
  padding-bottom: 50%;
  background-size: contain;
}

.image {
  width: 100%;
  padding-top: 42.9%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-gray {
  background-color: #dbdbdb;
}

.image {
  width: 100%;
  padding-bottom: 42.9% !important;
  padding-top: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.collapse-cat2-btn,
.collapse-cat2:hover {
  width: 100%;
  text-align: left;
  border-radius: 0;
  background-color: #fff;
  color: #000;
  border-bottom: 1px solid #dbdbdb;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  font-size: 16px;
}

.collapse-cat2,.collapse-cat2:hover {
  width: 100%;
  text-align: left;
  border-radius: 0;
  color: #000;
  border:0;
  font-size: 16px;
}

.collapse-details .card {
  border: 0;
}

.collapse-details .card .card-body {
  background-color: #f5f5f5;
}

#collapse-cat2 .card .card-body {
  padding: 0;
}
</style>
